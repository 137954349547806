import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import { useMnemonicPhraseStore } from '@/entities/mnemonic';
import { ERegisrationStep } from '@/features/registration';
import { AUTH_STORAGE_KEY } from '@/shared/constants/constants';
import {
  AccountStatus,
  ProfileType,
  useLoginViaPersonalAccountCodeMutation,
  useWhoamiLazyQuery,
} from '@/shared/generated/graphql';
import { useAuthStore } from '@/shared/store/auth.store';
import { useLoginStore } from '@/shared/store/login.store';

export const useAuth = () => {
  const [login] = useLoginViaPersonalAccountCodeMutation();
  const { setToken, setAccount, setIsBeneficiary } = useAuthStore();
  const { setWhoamiPublicKey } = useLoginStore();
  const [whoami] = useWhoamiLazyQuery();
  const { mnemonicPhrase } = useMnemonicPhraseStore();
  const router = useRouter();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    console.log('router query', router.query);
    if (router.query.authGrant) {
      localStorage.setItem(AUTH_STORAGE_KEY, String(router.query.authGrant));
      window.close();
    }
  }, [router.query]);

  useEffect(() => {
    const storageListener = async (event: StorageEvent) => {
      try {
        if (event.key !== AUTH_STORAGE_KEY) return;
        if (!event.newValue) return;
        setLoading(true);
        const authResponse = await login({
          variables: {
            code: event.newValue,
          },
        });
        setToken(authResponse.data?.loginViaPersonalAccountCode?.token);
        setAccount(authResponse.data?.loginViaPersonalAccountCode?.account);
        const profile = await whoami({
          context: {
            headers: {
              authorization:
                authResponse.data?.loginViaPersonalAccountCode?.token,
            },
          },
        });
        setIsBeneficiary(
          profile.data?.whoami.profile?.type === ProfileType.Beneficiary,
        );
        setWhoamiPublicKey(profile.data?.whoami.profile?.publicKey);

        if (profile.data?.whoami.profile?.status !== AccountStatus.Active) {
          await router.push(
            `/auth/register?step=${ERegisrationStep.CREATE_SEED}`,
          );
          return;
        }

        const isMnemonic = !mnemonicPhrase || mnemonicPhrase.length === 0;
        if (isMnemonic) {
          if (router.query) {
            await router.push(
              {
                pathname: '/auth/login/mnemonic-phrase',
                query: { reset: true },
              },
              undefined,
            );
            setLoading(false);
            return;
          }
          await router.push('/auth/login/mnemonic-phrase', undefined);
        }
        await router.push('/auth/login/enter-pin-code', undefined);

        setLoading(false);
      } catch (e) {
        setError(e as Error);
      } finally {
        localStorage.removeItem(AUTH_STORAGE_KEY);
        setLoading(false);
      }
    };

    window.addEventListener('storage', storageListener);

    return () => window.removeEventListener('storage', storageListener);
  }, [
    router,
    mnemonicPhrase,
    setAccount,
    setIsBeneficiary,
    setToken,
    login,
    whoami,
    setWhoamiPublicKey,
  ]);

  return useMemo(() => ({ error, loading }), [error, loading]);
};
